<template>
  <div>
    <div class="field has-addons">
      <input
        type="text"
        v-model="mSearchTerm"
        v-on:keyup.13="searchLocations"
        class="input"
        placeholder="Search locations"
      />
      <button
        class="button is-primary"
        :class="{ 'is-loading': isSearching }"
        @click="searchLocations"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'search']" />
        </span>
      </button>
    </div>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Status</th>
          <th>Name</th>
          <th>City</th>
          <th>Created on</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody v-for="location in locations" :key="location.Id">
        <tr>
          <td>
            <div class="tags">
              <span
                v-if="
                  !location.Locked && location.ActivationStatus === 'Activated'
                "
                class="tag is-success"
                >Active</span
              >
              <span
                v-if="
                  !location.Locked && location.ActivationStatus === 'Denied'
                "
                class="tag is-warning"
                >Denied</span
              >
              <span
                v-if="
                  !location.Locked && location.ActivationStatus === 'Neutral'
                "
                class="tag"
                >Pending</span
              >
              <span
                v-if="
                  !location.Locked && location.ActivationStatus === 'Requested'
                "
                class="tag is-info"
                >Request</span
              >
              <span v-if="location.Locked" class="tag is-danger">Locked</span>
              <span
                v-if="
                  !location.Locked && location.ActivationStatus === 'Stopped'
                "
                class="tag is-dark"
                >Stopped</span
              >
            </div>
          </td>
          <td>
            <a @click="fireLocationClicked(location)">{{ location.Name }}</a>
          </td>
          <td>{{ location.City }}</td>
          <td>
            {{ location.CreatedOn | convertTimeStampToIsoDateTimeString }}
          </td>
          <td class="has-text-right">
            <a @click="fireLocationClicked(location)">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              Add
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  props: {
    activeOnly: {
      default: false,
      type: Boolean,
    },
    autoSearch: {
      default: false,
      type: Boolean,
    },
    channelAuthorizeOnly: {
      default: false,
      type: Boolean,
    },
    meetingtypeId: {
      default: 0,
      type: Number,
    },
    searchTerm: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      isSearching: false,
      itemsPerPage: 25,
      locations: [],
      mSearchTerm: this.searchTerm,
      page: 1,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  mounted() {
    if (this.autoSearch) {
      this.searchLocations()
    }
  },

  beforeDestroy() {
    this.locations = []
  },

  methods: {
    searchLocations() {
      this.isSearching = true
      let countryId = 0
      let channelId = 0

      locationProvider.methods
        .searchLocations(
          this.channel.Id,
          this.mSearchTerm,
          this.page,
          this.itemsPerPage
        )
        .then((response) => {
          this.isSearching = false
          this.locations = response.data.Results
        })
    },

    fireLocationClicked(location) {
      this.locations = []
      this.mSearchTerm = ''
      this.$emit('locationClicked', location)
    },
  },
}
</script>
