var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"field has-addons"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mSearchTerm),expression:"mSearchTerm"}],staticClass:"input",attrs:{"type":"text","placeholder":"Search locations"},domProps:{"value":(_vm.mSearchTerm)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.searchLocations.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.mSearchTerm=$event.target.value}}}),_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.isSearching },on:{"click":_vm.searchLocations}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'search']}})],1)])]),_c('table',{staticClass:"table is-fullwidth is-striped"},[_vm._m(0),_vm._l((_vm.locations),function(location){return _c('tbody',{key:location.Id},[_c('tr',[_c('td',[_c('div',{staticClass:"tags"},[(
                !location.Locked && location.ActivationStatus === 'Activated'
              )?_c('span',{staticClass:"tag is-success"},[_vm._v("Active")]):_vm._e(),(
                !location.Locked && location.ActivationStatus === 'Denied'
              )?_c('span',{staticClass:"tag is-warning"},[_vm._v("Denied")]):_vm._e(),(
                !location.Locked && location.ActivationStatus === 'Neutral'
              )?_c('span',{staticClass:"tag"},[_vm._v("Pending")]):_vm._e(),(
                !location.Locked && location.ActivationStatus === 'Requested'
              )?_c('span',{staticClass:"tag is-info"},[_vm._v("Request")]):_vm._e(),(location.Locked)?_c('span',{staticClass:"tag is-danger"},[_vm._v("Locked")]):_vm._e(),(
                !location.Locked && location.ActivationStatus === 'Stopped'
              )?_c('span',{staticClass:"tag is-dark"},[_vm._v("Stopped")]):_vm._e()])]),_c('td',[_c('a',{on:{"click":function($event){return _vm.fireLocationClicked(location)}}},[_vm._v(_vm._s(location.Name))])]),_c('td',[_vm._v(_vm._s(location.City))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("convertTimeStampToIsoDateTimeString")(location.CreatedOn))+" ")]),_c('td',{staticClass:"has-text-right"},[_c('a',{on:{"click":function($event){return _vm.fireLocationClicked(location)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1),_vm._v(" Add ")])])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("City")]),_c('th',[_vm._v("Created on")]),_c('th',[_vm._v(" ")])])])
}]

export { render, staticRenderFns }